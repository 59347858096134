<template>
  <div class="delivery-order-goods-container">
    <my-nav-bar
      :title="$route.query.client_name"
      left-text="返回"
      left-arrow
    />
    <div class="app-main-container delivery-order-goods-container__content">
      <div class="delivery-order-goods-tips">提示：往左边滑动可以加入退货</div>
      <van-swipe-cell v-for="(item, index) in goodses" :key="index">
        <div class="delivery-order-goods-container__cell">
          <div class="delivery-order-goods-container__cell__image">
            <van-image width="2.26666rem" height="2.26666rem" :src="item.image" />
          </div>
          <div class="delivery-order-goods-container__cell__info">
            <div class="delivery-order-goods-container__cell__info__name">{{ item.name }}</div>
            <div class="delivery-order-goods-container__cell__info__size">
              <span>规格：1*{{ item.size }}</span>
            </div>
            <van-row>
              <van-col :span="12">
                <div class="delivery-order-goods-container__cell__info__price">
                  单价：<span>￥{{ item.price }}</span>
                </div>
              </van-col>
              <van-col :span="12">
                <div class="delivery-order-goods-container__cell__info__volume">
                  <template v-if="item.large_volume > 0">
                    <span>{{ item.large_volume }}{{ item.large_unit }}</span>
                    <i style="padding: 0 5px" />
                  </template>
                  <template v-if="item.least_volume > 0">
                    <span>{{ item.least_volume }}{{ item.least_unit }}</span>
                  </template>
                </div>
              </van-col>
            </van-row>
            <div class="delivery-order-goods-container__cell__info__amount">
              合计：<span>￥{{ item.amount }}</span>
            </div>
          </div>
        </div>
        <template #right>
          <van-button square block text="退货" type="danger" @click="handleReturn(item)" />
        </template>
      </van-swipe-cell>
    </div>
    <van-dialog
      v-model="show"
      title="加入退货"
      show-cancel-button
      confirm-button-text="确定"
      confirm-button-color="#1989fa"
      @confirm="goodsReturn"
    >
      <div v-if="goods" class="dialog-input-content">
        <van-field
          v-model="goodVolume"
          type="number"
          label="完好的"
          placeholder="请输入完好的数量"
        />
        <van-field
          v-model="badVolume"
          type="number"
          label="损坏的"
          placeholder="请输入损坏的数量"
        />
        <van-cell title="备注" :value="remarkType | reasonFilter" is-link @click="showReasonView = true" />
      </div>
    </van-dialog>
    <van-action-sheet v-model="showReasonView" :actions="reasons" @select="selectReason" />
  </div>
</template>

<script>
import deliveryOrderApi from '@/api/delivery_order'
import goodsReturnApi from '@/api/goods_return'
import myNavBar from '@/components/my-nav-bar'
import { unitsToVolume } from '@/utils'
export default {
  name: 'DeliveryOrderGoods',
  components: { myNavBar },
  filters: {
    reasonFilter(type) {
      const reasonMap = {
        1: '客户取消',
        2: '没上到货',
        3: '客户退货',
        4: '开错单',
        5: '上错货',
        6: '取回收条'
      }
      return reasonMap[type]
    }
  },
  data() {
    return {
      goodses: [],
      goods: null,
      show: false,
      goodVolume: '',
      badVolume: '',
      showReasonView: false,
      remarkType: 3,
      reasons: [
        { type: 1, name: '客户取消' },
        { type: 2, name: '没上到货' },
        { type: 3, name: '客户退货' },
        { type: 4, name: '开错单' },
        { type: 5, name: '上错货' },
        { type: 6, name: '取回收条' }
      ]
    }
  },
  created() {
    this.getGoodses()
  },
  methods: {
    getGoodses() {
      const params = {
        client_id: this.$route.query.client_id,
        bill_reject_id: this.$route.query.bill_reject_id
      }
      deliveryOrderApi.goods(params).then(res => {
        this.goodses = res.data
      })
    },
    handleReturn(goods) {
      this.goods = goods
      this.show = true
      this.goodVolume = unitsToVolume(goods.large_volume, goods.least_volume, goods.size)
    },
    goodsReturn() {
      if (Number(this.largeVolume) <= 0 && Number(this.leastVolume) <= 0) {
        this.fail('退货数量不能为0')
        return
      }

      this.beginLoad()
      const data = {
        client_id: this.$route.query.client_id,
        goods_id: this.goods.goods_id,
        order_goods_id: this.goods.id,
        good_volume: Number(this.goodVolume),
        bad_volume: Number(this.badVolume),
        remark_type: this.remarkType,
        bill_reject_id: this.$route.query.bill_reject_id
      }
      goodsReturnApi.add(data).then(res => {
        this.success(res.msg)
      })
    },
    selectReason(action) {
      this.remarkType = action.type
      this.showReasonView = false
    }
  }
}
</script>

<style lang="scss" scoped>
  .delivery-order-goods-container {
    .van-swipe-cell {
      .van-button {
        height: 100%;
      }
    }
    &__content {
      padding: 15px 15px 5px 15px;
    }
    &__cell {
      margin-bottom: 10px;
      background-color: #fff;
      padding: 15px;
      display: flex;
      align-items: start;
      &__image {
        display: inline-block;
      }
      &__info {
        display: inline-block;
        padding-left: 10px;
        width: 220px;
        &__name {
          padding-bottom: 4px;
        }
        &__size {
          font-size: 13px;
          padding-bottom: 4px;
          color: #A3A3A3;
        }
        &__volume {
          font-size: 13px;
          padding-bottom: 4px;
          text-align: right;
          color: #A3A3A3;
        }
        &__price, &__amount {
          font-size: 13px;
          span {
            color: #ee0a24;
          }
        }
      }
    }
  }
  .delivery-order-goods-tips {
    padding-bottom: 10px;
    color: #A3A3A3;
  }
</style>
