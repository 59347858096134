var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "delivery-order-goods-container"
  }, [_c('my-nav-bar', {
    attrs: {
      "title": _vm.$route.query.client_name,
      "left-text": "返回",
      "left-arrow": ""
    }
  }), _c('div', {
    staticClass: "app-main-container delivery-order-goods-container__content"
  }, [_c('div', {
    staticClass: "delivery-order-goods-tips"
  }, [_vm._v("提示：往左边滑动可以加入退货")]), _vm._l(_vm.goodses, function (item, index) {
    return _c('van-swipe-cell', {
      key: index,
      scopedSlots: _vm._u([{
        key: "right",
        fn: function fn() {
          return [_c('van-button', {
            attrs: {
              "square": "",
              "block": "",
              "text": "退货",
              "type": "danger"
            },
            on: {
              "click": function click($event) {
                return _vm.handleReturn(item);
              }
            }
          })];
        },
        proxy: true
      }], null, true)
    }, [_c('div', {
      staticClass: "delivery-order-goods-container__cell"
    }, [_c('div', {
      staticClass: "delivery-order-goods-container__cell__image"
    }, [_c('van-image', {
      attrs: {
        "width": "2.26666rem",
        "height": "2.26666rem",
        "src": item.image
      }
    })], 1), _c('div', {
      staticClass: "delivery-order-goods-container__cell__info"
    }, [_c('div', {
      staticClass: "delivery-order-goods-container__cell__info__name"
    }, [_vm._v(_vm._s(item.name))]), _c('div', {
      staticClass: "delivery-order-goods-container__cell__info__size"
    }, [_c('span', [_vm._v("规格：1*" + _vm._s(item.size))])]), _c('van-row', [_c('van-col', {
      attrs: {
        "span": 12
      }
    }, [_c('div', {
      staticClass: "delivery-order-goods-container__cell__info__price"
    }, [_vm._v(" 单价："), _c('span', [_vm._v("￥" + _vm._s(item.price))])])]), _c('van-col', {
      attrs: {
        "span": 12
      }
    }, [_c('div', {
      staticClass: "delivery-order-goods-container__cell__info__volume"
    }, [item.large_volume > 0 ? [_c('span', [_vm._v(_vm._s(item.large_volume) + _vm._s(item.large_unit))]), _c('i', {
      staticStyle: {
        "padding": "0 5px"
      }
    })] : _vm._e(), item.least_volume > 0 ? [_c('span', [_vm._v(_vm._s(item.least_volume) + _vm._s(item.least_unit))])] : _vm._e()], 2)])], 1), _c('div', {
      staticClass: "delivery-order-goods-container__cell__info__amount"
    }, [_vm._v(" 合计："), _c('span', [_vm._v("￥" + _vm._s(item.amount))])])], 1)])]);
  })], 2), _c('van-dialog', {
    attrs: {
      "title": "加入退货",
      "show-cancel-button": "",
      "confirm-button-text": "确定",
      "confirm-button-color": "#1989fa"
    },
    on: {
      "confirm": _vm.goodsReturn
    },
    model: {
      value: _vm.show,
      callback: function callback($$v) {
        _vm.show = $$v;
      },
      expression: "show"
    }
  }, [_vm.goods ? _c('div', {
    staticClass: "dialog-input-content"
  }, [_c('van-field', {
    attrs: {
      "type": "number",
      "label": "完好的",
      "placeholder": "请输入完好的数量"
    },
    model: {
      value: _vm.goodVolume,
      callback: function callback($$v) {
        _vm.goodVolume = $$v;
      },
      expression: "goodVolume"
    }
  }), _c('van-field', {
    attrs: {
      "type": "number",
      "label": "损坏的",
      "placeholder": "请输入损坏的数量"
    },
    model: {
      value: _vm.badVolume,
      callback: function callback($$v) {
        _vm.badVolume = $$v;
      },
      expression: "badVolume"
    }
  }), _c('van-cell', {
    attrs: {
      "title": "备注",
      "value": _vm._f("reasonFilter")(_vm.remarkType),
      "is-link": ""
    },
    on: {
      "click": function click($event) {
        _vm.showReasonView = true;
      }
    }
  })], 1) : _vm._e()]), _c('van-action-sheet', {
    attrs: {
      "actions": _vm.reasons
    },
    on: {
      "select": _vm.selectReason
    },
    model: {
      value: _vm.showReasonView,
      callback: function callback($$v) {
        _vm.showReasonView = $$v;
      },
      expression: "showReasonView"
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }